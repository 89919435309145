<template>
  <div class="gzr_pdfbox">
    <iframe ref="iframe" :src="`./pdfjs/web/viewer.html?file=${this.fileUrl}&type=infoCollege`" frameborder="0" scrolling="no" seamless="seamless" noResize class="iframe"></iframe>
  </div>
</template>
<script>
export default {
  data () {
    return {
      fileUrl: ''
    }
  },
  created () {
    this.fileUrl = this.$route.query.url
    window.myid = () => {
    }
    window.hide = () => {
    }
  }
}
</script>
<style lang="less" scoped>
.gzr_pdfbox {
  width: 100%;
  min-height: calc(100% - 284px);
  background-color: #f5f7fb;
  .iframe {
    display: block;
    width: 1200px;
    height: calc(100vh - 84px);
    margin: 0 auto 10px;
  }
}
</style>
